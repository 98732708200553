exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adolescentes-tsx": () => import("./../../../src/pages/adolescentes.tsx" /* webpackChunkName: "component---src-pages-adolescentes-tsx" */),
  "component---src-pages-adultos-tsx": () => import("./../../../src/pages/adultos.tsx" /* webpackChunkName: "component---src-pages-adultos-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-familias-tsx": () => import("./../../../src/pages/familias.tsx" /* webpackChunkName: "component---src-pages-familias-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infancia-tsx": () => import("./../../../src/pages/infancia.tsx" /* webpackChunkName: "component---src-pages-infancia-tsx" */),
  "component---src-pages-padres-tsx": () => import("./../../../src/pages/padres.tsx" /* webpackChunkName: "component---src-pages-padres-tsx" */)
}

